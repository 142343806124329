<template>
  <div class="character-profile vx-card main-box top-zero-radius">
    <profile-main-box :profile="getCharacterProfile"
                      :default-action="profileStatus"
                      :actionsList="actionsList"
                      @showCharacterUsersList="handleChangeProfileStatus('showCharacterUsersList')"
                      @editCharacter="handleChangeProfileStatus('editCharacter')"
                      @characterActivitiesLog="handleChangeProfileStatus('characterActivitiesLog')">

      <edit-character v-if="profileStatus === 'editCharacter'" :character-id="characterId || character.id" @edit="$router.push({name: 'characters'})"/>

      <character-user-list v-if="profileStatus === 'showCharacterUsersList'" :character-id="characterId || character.id"/>

      <character-activities-log v-if="profileStatus === 'characterActivitiesLog'" :character-id="characterId || parseInt($route.params.id)" />
    </profile-main-box>
  </div>
</template>

<script>
  import ProfileMainBox from "../../../../components/profileMainBox/profileMainBox2.vue"
  import {getCharacter} from "@/http/requests/characters";
  import CharacterUserList from "@/views/admin/characters/users/list/characterUserList.vue";
  import EditCharacter from "@/views/admin/characters/edit/editCharacter.vue";
  import CharacterActivitiesLog from "@/views/admin/characters/activitiesLog/characterActivitiesLog.vue";
  export default {
    name: "characterProfile",
    components: {CharacterActivitiesLog, EditCharacter, CharacterUserList, ProfileMainBox},
    props: {
      characterId: {
        type: [Number, String],
        default: 0
      }
    },
    data () {
      return {
        profileStatus: 'showCharacterUsersList',
        character: {
          id: 0,
          name: ''
        },
        actionsList: [
          {
            event: 'editCharacter',
            name: 'editCharacter',
            header: this.$t('characters.profile.actions.edit'),
            // preview: {
            //     value: addComma(25000) + ` ${this.$locale.currency()}`,
            //     color: 'success'
            // },
            i18n: '',
            icon: 'EDIT',
            iconPack: 'useral'
          },
          {
            event: 'characterActivitiesLog',
            name: 'characterActivitiesLog',
            params: {id: this.courtId || this.$route.params.id},
            header: this.$t('characters.profile.actions.logs'),
            i18n: '',
            icon: 'LAST_ACTIVITIES',
            iconPack: 'useral',
            permission: 'activity_logs.show'
          }
        ],
        actions: {
          toolbar: [],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: 'characters'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    computed: {
      getCharacterProfile () {
        const character = {
          avatar: require('@/assets/images/no-image.png'),
          name: this.character.name,
          details: [],
          importantData: []
        }

        return character
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      })

      this.getCharacter()
    },
    methods: {
      getCharacter () {
        getCharacter(this.characterId || this.$route.params.id).then((response) => {
          const character = response.data.data
          this.character = {
            id: character.id,
            name: character.name || ''
          }
          this.actionsList.splice(1, 0, {
              event: 'showCharacterUsersList',
              name: 'characterUserList',
              header: this.$t('characters.profile.actions.usersList', {name: this.character.name}),
              i18n: '',
              icon: 'icon-user',
              iconPack: 'feather'
            })
          if (!this._isBeingDestroyed) {
            this.$store.dispatch('setPageTitle', this.$t('characters.profile.dynamicTitle', {name: this.character.name}))
          }
        })
      },
      handleChangeProfileStatus (status) {
        this.profileStatus = ''
        this.$nextTick(() => {
          this.profileStatus = status
        })
      }
    }
  }
</script>

<style scoped>

</style>
