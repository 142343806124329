<template>
  <div class="insert-character-user">
    <draggable-dynamic-table ref="insertCharacterUsersList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             :suggests="suggests"
                             @row:deleted="handleDeleteRow($event)"
                             @suggest:selected="handleInsertNewRow"
                             @suggestPrompt:opened="suggestPromptStatus = true, rowIndex = $event"
                             v-model="data"/>

    <!-- users list prompt -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="suggestPromptStatus"
      @close="suggestPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('selectUsers')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.select.title')}}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="suggestPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <select-users @prompt:reload="handleReloadPrompt()"
                          @selected="handleSuggestSelected($event, rowIndex)"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>


    <button id="insertCharacterUsersBTN" class="useral-action-button" @click="sendData"></button>
  </div>
</template>

<script>
  import axios from 'axios'
  import SelectUsers from "../../../customers/select/selectUsers";
  import { insertUserToCharacter } from "../../../../../http/requests/characters"
  import CustomIcon from "../../../../../components/customIcon/customIcon";

  export default {
    name: "insertCharacterUser",
    components: {CustomIcon, SelectUsers},
    props: {
      characterId: 0
    },
    data () {
      return {
        suggestPromptStatus: false,
        page: 1,
        endedList: false,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'delete',
            i18n: 'characters.user.table.header.delete',
            width: '70px',
            minWidth: 70,
            /*sortable: true,*/
            actions: true,
            showAction: 'always',
            action: {
              color: 'danger',
              icon: 'TRASH',
              iconPack: 'useral',
              type: 'button'
            },
            event: 'row:deleted'
          },
          {
            field: 'phoneNumber',
            i18n: 'characters.user.table.header.phoneNumber',
            width: '50%',
            minWidth: 200,
          },
          {
            field: 'name',
            i18n: 'characters.user.table.header.name',
            align: 'center',
            width: '50%',
            minWidth: 200,
            /*sortable: true,*/
            editable: true,
            returnValue: false,
            suggest: true,
            suggestPrompt: true
          }
        ],
        suggests: {
          name: []
        },
        data: [],
        usersList: []
      }
    },
    created () {
      this.handleInsertNewRow()
    },
    methods: {
      handleReloadPrompt (value) {
        this.suggestPromptStatus = false
        setTimeout(() => {
          this.suggestPromptStatus = true
        }, 100)
      },
      handleSuggestSelected (list, index) {
        const rows = JSON.parse(JSON.stringify(this.data))
        if (rows[rows.length - 1].hasOwnProperty('id') && (rows[rows.length - 1].id === 0 || rows[rows.length - 1].id === '')) {
          rows.splice(rows.length - 1, 1)
        }
        this.data = []
        let i = 0
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            i++
            const newData = {}

            this.columnsLabel.forEach((column) => {
              if (data.hasOwnProperty(column.field)) newData[column.field] = data[column.field]
              else newData[column.field] = ''
            })
            newData.flag = 'New'

            if (data.hasOwnProperty('id')) {
              newData.id = data.id
              const isExist = rows.map((elm) => {
                return elm.id
              }).indexOf(data.id) > -1
              if (isExist) {
                let text = this.$t('characters.user.notifications.userExist')
                if (newData.name) text = this.$t('characters.user.notifications.dynamicUserExist', {name: newData.name})
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text,
                  time: 2400,
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              } else if (i === 1) rows[index] = newData
              else rows.push(newData)
            } else if (i === 1) rows[index] = newData
            else rows.push(newData)
          }
        })

        this.data = rows
        this.suggestPromptStatus = false
        this.handleInsertNewRow()
      },
      getSuggestList () {
        const users = JSON.parse(JSON.stringify({data: this.usersList}))
        const items = this.data.map((elm) => {
          return elm.id
        })
        const list = []

        users.data.forEach((user) => {
          if (items.indexOf(user.value.id) === -1) {
            list.push(user)
          }
        })

        this.suggests.name = JSON.parse(JSON.stringify(list))
      },
      handleInsertNewRow () {
        this.getSuggestList()

        const checkName = this.data.map((elm) => {
          return elm.name === ''
        }).indexOf(true) > -1
        if (!checkName) {
          this.data.push({
            id: 0,
            name: '',
            price: '',
            flag: 'NEW'
          })
        }
      },
      handleDeleteRow (row) {
        if (row) {
          const index = this.data.map((elm) => {
            return elm.id
          }).indexOf(row.id)
          if (index > -1 && row.id !== 0) {
            // this.data.splice(index, 1)
            this.data.splice(index, 1)
            /*setTimeout(() => {
              this.getSuggestList()
            }, 100)*/
          }
        }
      },
      sendData () {
        if (this.data.length > 1) {
          let users = []
          this.data.forEach(user => {
            if (user.id > 0) {
              users.push(user.id)
            }
          })
          const payload = {
            users: users
          }
          insertUserToCharacter(this.characterId, payload).then(response => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('characters.user.notifications.insert.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })
            this.$emit('insert')
          })
            .catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('characters.user.notifications.insert.error'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            })
        }
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss">
  .insert-character-user {
    height: 100%;

    .draggable-dynamic-table {
      .new-suggest-modal-button {
        display: none;
      }

      .suggest-modal-button {
        right: 7px !important;
      }
    }
  }
</style>
