<template>
  <div class="character-users-list">
    <draggable-dynamic-table ref="characterUsersList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             @load:more="getCharacterUsersList"
                             @row:deleted="handleDeleteRow($event)"
                             v-model="data"/>


    <!-- show character users prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertCharacterUsersListPromptStatus"
      @close="insertCharacterUsersListPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCharacterUsersBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.user.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertCharacterUsersListPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-character-user :character-id="characterId" @insert="handleReloadTable" />
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <vs-button id="deleteUserBTN"
               v-show="false"
               class="w-full mt-3"
               color="danger"
               v-if="checkUserPermissions('user.set_character')"
               @click="$refs.deleteCharacterConfirmation.showDialog()">
      {{ $t('characters.user.labels.delete') }}
    </vs-button>

    <custom-dialog ref="deleteCharacterConfirmation"
                   :title="$t('characters.user.confirmations.delete.title')"
                   :body="$t('characters.user.confirmations.delete.body', {name: deletedUserName})"
                   @accept="deleteUserCharacter"/>

    <button id="insertCharacterUserList" v-show="false"
            @click="insertCharacterUsersListPromptStatus = true" />
  </div>
</template>

<script>
  import axios from "axios";
  import { checkUserPermissions } from "../../../../../assets/js/functions";
  import {getCharacterUsersList, removeUserCharacter} from "../../../../../http/requests/characters"
  import InsertCharacterUser from "../insert/insertCharacterUser";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import CustomDialog from "../../../../../components/customDialog/customDialog";

  export default {
    name: "characterUserList",
    components: {CustomDialog, CustomIcon, InsertCharacterUser},
    props: {
      characterId: 0
    },
    data () {
      return {
        deletedUserName: '',
        deletedUserId: '',
        insertCharacterUsersListPromptStatus: false,
        page: 1,
        endedList: false,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'delete',
            i18n: 'characters.user.table.header.delete',
            width: '70px',
            minWidth: 70,
            /*sortable: true,*/
            actions: true,
            showAction: 'always',
            action: {
              color: 'danger',
              icon: 'TRASH',
              iconPack: 'useral',
              type: 'button'
            },
            event: 'row:deleted'
          },
          {
            field: 'phoneNumber',
            i18n: 'characters.user.table.header.phoneNumber',
            width: '50%',
            minWidth: 200,
            align: 'center',
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ],
          },
          {
            field: 'name',
            i18n: 'characters.user.table.header.name',
            align: 'center',
            width: '50%',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'rowNumber',
            i18n: 'characters.user.table.header.row',
            align: 'center',
            width: '70px',
            minWidth: 70,
            // sortable: true,
            footer: {}
          }
        ],
        data: [],
        actions: [
          {
            toolbar: [
              {
                id: 'insertCharacterUserList',
                icon: 'PLUS',
                iconPack: 'useral',
                color: 'success',
                permission: 'user.set_character'
              }
            ],
            leftToolbar: [
              {
                id: 'routeBackBTN',
                route: {name: this.$route.name === 'characterUserList' ? 'character' : 'characters'},
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created () {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      })
      this.$store.dispatch('setPageTitle', this.$t('characters.user.title'))

      this.getCharacterUsersList()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCharacterUsersList () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.characterUsersList && this.data.length === 0) this.$refs.characterUsersList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.characterUsersList && this.data.length > 0) this.$refs.characterUsersList.loadMoreStatus = 'Loading'

            getCharacterUsersList(this.page, this.characterId || this.$route.params.id).then(response => {
              const users = response.data.data

              this.total_count = response.data.pagination.total

              users.forEach((user) => {
                this.data.push({
                  rowNumber: this.data.length + 1,
                  id: user.id,
                  name: `${user.name} ${user.family}`,
                  phoneNumber: {
                    value: user.phone_number,
                    classes: this.$vs.rtl ? 'rtl' : 'ltr'
                  },
                  flag: 'SAVED'
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
              }

              if (this.$refs.characterUsersList) this.$refs.characterUsersList.loadMoreStatus = ''

            }).catch((error) => {
                if (this.$refs.characterUsersList && !axios.isCancel(error)) this.$refs.characterUsersList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      handleDeleteRow (row) {
        this.deletedUserName = row.name
        this.deletedUserId = row.id
        setTimeout(() => {
          this.handleClick('deleteUserBTN')
        }, 500)
      },

      deleteUserCharacter () {
        if (this.characterId === 1) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('characters.user.notifications.notAllowed'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        } else {
          removeUserCharacter(this.characterId, {users: [this.deletedUserId]}).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('characters.user.notifications.delete.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check-circle',
              iconPack: 'feather'
            })
            this.handleReloadTable()
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('characters.user.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        }
      },

      handleReloadTable () {
        this.data = []
        this.page = 1,
          this.endedList = false,
          this.insertCharacterUsersListPromptStatus = false
        setTimeout(() => {
          this.getCharacterUsersList()
        }, 100)
      },

      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .character-users-list {
    height: 100%;
  }
</style>
